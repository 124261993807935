<template>
    <div>
        <div v-if="authUserPermission['characteristic-item-name-view']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="row">
                    <div class="col-md-6">
                        <div id="cardCharacteristic" class="card mb-4">
                            <div class="card-header mt--4" id="table">
                                <div class="row">
                                    <div class="col-5">
                                        <el-select v-model="table.search"
                                            :placeholder="tt('minimum_type_char', { total: 3 })"
                                            :remote-method="querySearchStandardItemName" :loading="loading" filterable
                                            remote reserve-keyword @change="filter()" style="margin-bottom: -15px">
                                            <el-option v-for="si in standardItemName" :key="si.inc"
                                                :label="si.inc + ' - ' + si.item_name"
                                                :value="si.inc + ' - ' + si.item_name"></el-option>
                                        </el-select>
                                    </div>
                                    <div class="col-7 text-right">
                                        <!-- <base-button style="margin-top:1px"
                                            v-if="authUserPermission['characteristic-item-name-create']" size="sm"
                                            type="default" @click="create" :disabled="!btnAdd">{{ tt('add')
                                            }}</base-button> -->
                                        <base-button style="margin-top:1px"
                                            v-if="authUserPermission['characteristic-item-name-create']" size="sm"
                                            type="default" @click="create" :disabled="!btnAdd">{{ tt('add')
                                            }}</base-button>
                                        <base-button style="margin-top:1px"
                                            v-if="authUserPermission['characteristic-item-name-update']" size="sm"
                                            type="default" @click="update" :disabled="!btnEdit">
                                            <span>{{ tt('edit') }}</span>
                                        </base-button>
                                        <el-dropdown trigger="click">
                                            <base-button class="el-dropdown-link" size="sm" type="default">
                                                {{ itemTypeFilter }}<i class="el-icon-arrow-down el-icon--right"></i>
                                            </base-button>
                                            <el-dropdown-menu slot="dropdown">
                                                <div class="container">
                                                    <p class="filter mb--1" @click="changeItemType('GEN')">GEN</p>
                                                    <p class="filter mb--1" @click="changeItemType('OEM')">OEM</p>
                                                    <p class="filter mb--1" @click="changeItemType('SERV')">SERV</p>
                                                    <p class="filter mb--1" @click="changeItemType('GEN_OEM')">GEN/OEM
                                                    </p>
                                                </div>
                                            </el-dropdown-menu>
                                        </el-dropdown>
                                    </div>
                                </div>
                            </div>
                            <div v-if="showTable">
                                <el-table height="400px" id="tableChar" class="table-responsive table-flush "
                                    header-row-class-name="thead-light" :data="table.data" ref="singleTable"
                                    highlight-current-row v-if="!onLoad" @current-change="handleCurrentChange"
                                    @selection-change="handleSelectionChange">
                                    <!-- <el-table-column type="selection" width="20"></el-table-column> -->
                                    <el-table-column :prop="tt('mandatory')" min-width="65px">
                                        <template v-slot="{ row }">
                                            <el-checkbox
                                                v-model="table.data[table.data.map(function (d) { return d['cin_code']; }).indexOf(row.cin_code)].mandatory"
                                                :disabled="!authUserPermission['characteristic-item-name-create']"></el-checkbox>
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="tt('characteristic')" :prop="tt('characteristic')"
                                        min-width="230px" sortable>
                                        <template v-slot="{ row }">
                                            {{ row.characteristic }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="tt('type')" :prop="tt('type')" min-width="100px" sortable>
                                        <template v-slot="{ row }">
                                            {{ row.item_type_code }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="tt('seq')" :prop="tt('sequence')" min-width="100px"
                                        sortable>
                                        <template v-slot="{ row }">
                                            <input type="number" class="form-control form-control-sm" aria-label="Small"
                                                aria-describedby="inputGroup-sizing-sm"
                                                v-model="table.data[table.data.map(function (d) { return d['cin_code']; }).indexOf(row.cin_code)].sequence"
                                                :disabled="!authUserPermission['characteristic-item-name-create']">
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="tt('seq_des')" :prop="tt('seq_shortdes')" min-width="120px"
                                        sortable>
                                        <template v-slot="{ row }">
                                            <input type="number" class="form-control form-control-sm" aria-label="Small"
                                                aria-describedby="inputGroup-sizing-sm"
                                                v-model="table.data[table.data.map(function (d) { return d['cin_code']; }).indexOf(row.cin_code)].sequence_shortdes"
                                                :disabled="!authUserPermission['characteristic-item-name-create']">
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="tt('separator')" :prop="tt('separator')" min-width="110px">
                                        <template v-slot="{ row }">
                                            <select name="" id="" class="form-control form-control-sm"
                                                v-model="table.data[table.data.map(function (d) { return d['cin_code']; }).indexOf(row.cin_code)].separator_code"
                                                :disabled="!authUserPermission['characteristic-item-name-create']">
                                                <option value=""></option>
                                                <option value=";">;</option>
                                                <option value="X">X</option>
                                                <option value="-">-</option>
                                                <option value="/">/</option>
                                                <option value=":">:</option>
                                                <option value="+">+</option>
                                            </select>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="action" width="100">
                                        <template v-slot="{ row }">
                                            <i v-if="authUserPermission['characteristic-item-name-delete']"
                                                class="fa fa-trash text-danger" @click="remove(row.cin_code)"></i>
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <!-- <table class="table" v-if="!onLoad">
                                    <thead class="thead-light">
                                        <tr>
                                            <th scope="col">{{ tt('characteristic') }}</th>
                                            <th scope="col">{{ tt('item_type') }}</th>
                                            <th scope="col">{{ tt('sequence') }}</th>
                                            <th scope="col">{{ tt('seq_shortdes') }}</th>
                                            <th scope="col">{{ tt('separator') }}</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(row, index) in table.data" v-bind:key="index" :class="{'selected-row': (currentRow.index == table.data.map(function(d) { return d['cin_code']; }).indexOf(row.cin_code)) ? true : false}" @click="handleCurrentChange(row, table.data.map(function(d) { return d['cin_code']; }).indexOf(row.cin_code))">
                                            <td>{{row.characteristic}}</td>
                                            <td>{{row.item_type_code}}</td>
                                            <td>
                                                <div class="input-group input-group-sm">
                                                    <input type="text" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" v-model="table.data[table.data.map(function(d) { return d['cin_code']; }).indexOf(row.cin_code)].sequence">
                                                </div>
                                            </td>
                                            <td>
                                                <div class="input-group input-group-sm">
                                                    <input type="text" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" v-model="table.data[table.data.map(function(d) { return d['cin_code']; }).indexOf(row.cin_code)].sequence_shortdes">
                                                </div>
                                            </td>
                                            <td>
                                                <select name="" id="" class="form-control form-control-sm" v-model="table.data[table.data.map(function(d) { return d['cin_code']; }).indexOf(row.cin_code)].separator">
                                                    <option value=""></option>
                                                    <option value=";">;</option>
                                                    <option value="X">X</option>
                                                    <option value="-">-</option>
                                                    <option value="/">/</option>
                                                    <option value=":">:</option>
                                                    <option value="+">+</option>
                                                </select>
                                            </td>
                                            <td>
                                                <i class="fa fa-trash text-danger" @click="remove(row.cin_code)"></i>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table> -->
                                <page-loading v-else />
                            </div>
                            <div class="text-center" style="height: 400px" v-else>
                                <br><br>
                                <img src="/assets/smartcat/data_is_empty.png" alt="Data Is Empty" width="350px">
                                <p class="text-muted">{{ tt('please_search_to_show_data') }}</p>
                            </div>
                            <div class="card-footer pb-0 ">
                                <span class="float-left">
                                    <base-pagination :page-count="table.page.last_page"
                                        v-model="table.page.current_page"
                                        @click.native="changePage(table.page.current_page)" size="sm"></base-pagination>
                                </span>
                                <span class="float-right">
                                    {{ tt('page_info', {size: (table.page.current_page - 1) * table.page.per_page + table.data.length, total: table.total})}}
                                </span>
                            </div>
                        </div>
                    </div>

                    <!-- VIEW CHAR VALUE -->
                    <div class="col-md-6">
                        <div id="cardCharacteristic" class="card mb-4">
                            <div class="card-header mt--4" style="padding-bottom:2px">
                                <div class="row">
                                    <div class="col-3">
                                        <h4>{{ tt('char_value') }}</h4>
                                    </div>
                                    <div class="col-4">
                                        <base-input input-classes="form-control-sm text-center border-radius-20"
                                            v-model="filterValue.search_char_value"
                                            :placeholder="tt('search_sample_answer')" v-on:keyup="filterCharValue"
                                            :disabled="!btnAddCharValue" />
                                    </div>
                                    <div class="col-5 text-right">
                                        <!-- <base-button :type="filterValue.empty ? 'danger' : 'default'" size="sm"
                                            @click="handleFilterValue()" :disabled="!currentRow.cin_code">
                                            <el-tooltip
                                                :content="filterValue.empty ? 'Blank Abbreviation is Actived' : 'Blank Abbreviation is Not Actived'"
                                                placement="top">
                                                <i :class="{ 'far fa-file': filterValue.empty, 'far fa-file-alt': !filterValue.empty }"></i>
                                            </el-tooltip>
                                        </base-button> -->
                                        <el-dropdown trigger="click" style="margin-right:5px" @command="handleFilterValue" disabled>
                                            <base-button class="el-dropdown-link" size="sm" type="default">
                                                <!-- {{ filterValueType }}<i class="fa fa-filter"></i> -->
                                                <el-tooltip
                                                    :content="filterValueType"
                                                    placement="top">
                                                    <i class="fa fa-filter"></i>
                                                </el-tooltip>
                                            </base-button>
                                            <el-dropdown-menu slot="dropdown">
                                                <el-dropdown-item :command="{action:'all_values'}" :disabled="(!formReplaceCharValue.char)">{{ tt('all_values') }}</el-dropdown-item>
                                                <el-dropdown-item :command="{action:'empty_abbrev'}" :disabled="(!formReplaceCharValue.char)">{{ tt('empty_abbrev') }}</el-dropdown-item>
                                                <el-dropdown-item :command="{action:'count_zero'}" :disabled="(!formReplaceCharValue.char)">{{ tt('count_zero') }}</el-dropdown-item>
                                            </el-dropdown-menu>
                                        </el-dropdown>
                                        <base-button size="sm" type="default" @click="replaceCharValue"
                                            :disabled="tableCharValue.data.length == 0"
                                            v-if="authUserPermission['characteristic-item-name-create-char-value']">
                                            <el-tooltip content="Replace" placement="top">
                                                <i class="fa fa-object-ungroup" aria-hidden="true"></i>
                                            </el-tooltip>
                                        </base-button>
                                        <base-button size="sm" type="default" @click="createCharValue"
                                            :disabled="!btnAddCharValue"
                                            v-if="authUserPermission['characteristic-item-name-create-char-value']">
                                            <el-tooltip content="Add" placement="top">
                                                <i class="fa fa-plus" aria-hidden="true"></i>
                                            </el-tooltip>
                                        </base-button>
                                        <base-button
                                            v-if="authUserPermission['characteristic-item-name-update-char-value']"
                                            size="sm" type="default" @click="updateValue"
                                            :disabled="(tableCharValue.data.length == 0 || btnEditValue)">
                                            <el-tooltip content="Edit" placement="top">
                                                <i class="fa fa-wrench" aria-hidden="true" v-if="!btnEditValue"></i>
                                                <i class="fas fa-spinner fa-spin" aria-hidden="true" v-else></i>
                                            </el-tooltip>
                                        </base-button>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <el-table id="tableChar" height="400px" class="table-responsive table-flush"
                                    header-row-class-name="thead-light" :data="tableCharValue.data"
                                    v-if="!onLoadCharValue">
                                    <el-table-column :label="tt('char_value')" :prop="tt('char_value')"
                                        min-width="250px" sortable>
                                        <!-- <template v-slot="{row}">
                                            {{row.characteristic_value}}
                                        </template> -->
                                        <template v-slot="{ row }">
                                            <input type="text" class="form-control form-control-sm" aria-label="Small"
                                                aria-describedby="inputGroup-sizing-sm"
                                                v-model="tableCharValue.data[tableCharValue.data.map(function (d) { return d['id']; }).indexOf(row.id)].characteristic_value"
                                                :disabled="!authUserPermission['characteristic-item-name-update-char-value']">
                                        </template>
                                    </el-table-column>

                                    <el-table-column min-width="60px">
                                        <!-- <template v-slot="{row}">
                                            {{row.characteristic_value}}
                                        </template> -->
                                        <template v-slot:header>
                                            <span style="cursor:pointer" @click="handleCopyAll()">
                                                <i class="fa fa-arrow-right"></i>
                                            </span>
                                        </template>

                                        <template v-slot="{ row }">
                                            <span style="cursor:pointer" @click="handleCopy(row)">
                                                <i class="fa fa-arrow-right"></i>
                                            </span>
                                        </template>
                                    </el-table-column>

                                    <el-table-column :label="tt('abbrev')" :prop="tt('abbrev')" min-width="160px"
                                        sortable>
                                        <!-- <template v-slot="{row}">
                                            {{row.abbreviation}}
                                        </template> -->
                                        <template v-slot="{ row }">
                                            <input type="text" class="form-control form-control-sm" aria-label="Small"
                                                aria-describedby="inputGroup-sizing-sm"
                                                v-model="tableCharValue.data[tableCharValue.data.map(function (d) { return d['id']; }).indexOf(row.id)].abbreviation"
                                                :disabled="!authUserPermission['characteristic-item-name-update-char-value']">
                                        </template>
                                    </el-table-column>



                                    <el-table-column :label="tt('status')" :prop="tt('status')" min-width="107px"
                                        align="left" sortable>
                                        <!-- <template v-slot="{row}">
                                            {{(row.approved == 1) ? 'Yes' : 'No'}}
                                        </template> -->
                                        <template v-slot="{ row }">
                                            <!-- <el-select class="select-danger" v-model="tableCharValue.data[tableCharValue.data.map(function(d) { return d['id']; }).indexOf(row.id)].approved" :placeholder="tt('choose_approval')">
                                                <el-option class="select-danger" value="0" label="Not Approved" key="Not Approved" :selected="row.approved == 0"></el-option>
                                                <el-option class="select-danger" value="1" label="Approved" key="Approved" :selected="row.approved == 1"></el-option>
                                            </el-select> -->
                                            <el-checkbox
                                                v-model="tableCharValue.data[tableCharValue.data.map(function (d) { return d['id']; }).indexOf(row.id)].approved"
                                                :checked="tableCharValue.data[tableCharValue.data.map(function (d) { return d['id']; }).indexOf(row.id)].approved == 1"
                                                :disabled="!authUserPermission['characteristic-item-name-update-char-value']"></el-checkbox>
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="tt('count')" :prop="tt('count')" min-width="106px"
                                        sortable>
                                        <template v-slot="{ row }">
                                            {{ row.part_characteristic_value.length }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column :prop="action" sortable
                                        v-if="authUserPermission['characteristic-item-name-delete-char-value']">
                                        <template v-slot="{ row }">
                                            <span style="cursor:pointer;margin-right:5px"><i
                                                    class="fa fa-table text-primary" @click="workbench(row)"></i></span>
                                            <span style="cursor:pointer"><i class="fa fa-trash text-danger"
                                                    @click="removeCharValue(row.id)"></i></span>
                                        </template>
                                    </el-table-column>
                                    <!-- <el-table-column prop="action" width="80">
                                        <template v-slot="{row}">
                                            <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true" v-if="authUserPermission['characteristic-item-name-update-char-value'] || authUserPermission['characteristic-item-name-delete-char-value']">
                                                <span class="btn btn-sm btn-icon-only text-light">
                                                    <i class="fas fa-ellipsis-v mt-2"></i>
                                                </span>
                                                <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                                    <el-dropdown-item :command="{action:'editCharValue', data:tableCharValue.data.map(function(d) { return d['characteristic_value']; }).indexOf(row.characteristic_value)}" v-if="authUserPermission['characteristic-item-name-update-char-value']">{{ tt('edit') }}</el-dropdown-item>
                                                    <el-dropdown-item :command="{action:'dataCharValue', data:row}">{{ tt('data') }}</el-dropdown-item> 
                                                    <el-dropdown-item :command="{action:'removeCharValue', data:row.id}" v-if="authUserPermission['characteristic-item-name-delete-char-value']">{{ tt('delete') }}</el-dropdown-item>                                        
                                                </el-dropdown-menu>
                                            </el-dropdown>
                                        </template>
                                    </el-table-column> -->
                                </el-table>
                                <page-loading v-else />
                            </div>
                            <div class="card-footer pb-0 ">
                                <span class="float-left">
                                    <base-pagination :page-count="tableCharValue.page.last_page"
                                        v-model="tableCharValue.page.current_page"
                                        @click.native="changePageCharValue(tableCharValue.page.current_page)"
                                        size="sm"></base-pagination>
                                </span>
                                <span class="float-right">
                                    {{ tt('page_info', {
                                            size: (tableCharValue.page.current_page - 1) *
                                                tableCharValue.page.per_page + tableCharValue.data.length, total:
                                                tableCharValue.total
                                        }) 
                                    }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- remote search  -->
            <validation-observer v-slot="{ invalid }">
                <modal :show.sync="form.show">
                    <template slot="header">
                        <h5 class="modal-title">{{ form.title }}</h5>
                    </template>
                    <div>
                        <base-alert type="danger" v-if="errorMessage">
                            <p><strong>{{ errorMessage.message }}</strong></p>
                            <div v-if="errorMessage.data.length != 0">
                                <span v-for="message in errorMessage.data">
                                    {{ message[0] }}<br>
                                </span>
                            </div>
                        </base-alert>

                        <label class="form-control-label">{{ tt('characteristic_master') }} <span
                                class="text-danger">*</span></label>
                        <div class="row">
                            <!-- <el-autocomplete class="inline-input col-md-12"
                                v-model="characteristicItemName.characteristic_code"
                                :fetch-suggestions="querySearchCharacteristicMaster"
                                :placeholder="tt('choose_characteristic_master')" :trigger-on-focus="false"
                                @select="" size="large"></el-autocomplete> -->

                            <el-select class="inline-input col-md-12"
                                v-model="characteristicItemName.characteristic_code"
                                :placeholder="tt('choose_characteristic_master')"
                                :remote-method="querySearchCharacteristicMaster" :loading="loading" filterable remote
                                reserve-keyword size="large">
                                <el-option v-for="sc in selectCharacteristic" :key="sc.characteristic_code" :value="sc.characteristic_code">
                                    <template #default>
                                        <b>{{ sc.characteristic }}</b>
                                        <span>
                                            ({{ sc.characteristic_code }}) 
                                            ({{ sc.item_type_code }}) 
                                            ({{ sc.single_or_multiple === 1 ? 'SINGLE' : 'MULTIPLE' }}) 
                                            <span v-if="sc.label">({{ sc.label }})</span>
                                        </span>
                                    </template>
                                </el-option>
                            </el-select>
                            <!-- <el-checkbox v-model="characteristicItemName.mandatory"></el-checkbox> -->
                        </div>
                        <br>
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="form.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="save" :disabled="btnSave.onLoading || invalid">
                            <span v-if="btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait')
                                }}</span>
                            <span v-else>
                                <span v-if="form.add">{{ tt('add') }}</span>
                                <span v-else>{{ tt('edit') }}</span>
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>

            <validation-observer v-slot="{ invalid }">
                <modal :show.sync="formCharValue.show">
                    <template slot="header">
                        <h5 class="modal-title">{{ formCharValue.title }}</h5>
                    </template>
                    <div>
                        <!-- <base-alert type="danger" v-if="errorMessage">
                            <p><strong>{{ errorMessage.message }}</strong></p>
                            <div v-if="errorMessage.data.length != 0">
                                <span v-for="message in errorMessage.data">
                                    {{ message[0] }}<br>
                                </span>
                            </div>
                        </base-alert> -->

                        <div v-if="formCharValue.add">
                            <label class="form-control-label">{{ tt('sample_answer') }} <span
                                    class="text-danger">*</span></label>
                            <base-input :name="tt('sample_answer')" :placeholder="tt('sample_answer')"
                                v-model="characteristicValue.characteristic_value" rules="required"></base-input>
                        </div>
                        <div v-else>
                            <label class="form-control-label">{{ tt('current_value') }} <span
                                    class="text-danger">*</span></label>
                            <base-input :name="tt('current_value')" :placeholder="tt('current_value')"
                                v-model="characteristicValue.current_value" readonly></base-input>

                            <label class="form-control-label">{{ tt('update_to') }} <span
                                    class="text-danger">*</span></label>
                            <base-input :name="tt('update_to')" :placeholder="tt('update_to')"
                                v-model="characteristicValue.characteristic_value" rules="required|max:95"></base-input>
                        </div>

                        <label class="form-control-label">{{ tt('abbreviation_to') }}</label>
                        <base-input :name="tt('abbreviation_to')" :placeholder="tt('abbreviation_to')"
                            v-model="characteristicValue.abbreviation"></base-input>

                        <label class="form-control-label">{{ tt('approval') }} <span
                                class="text-danger">*</span></label>
                        <base-input :name="tt('approval')" rules="required">
                            <el-select class="select-danger" v-model="characteristicValue.approved"
                                :placeholder="tt('choose_approval')">
                                <el-option class="select-danger" value="0" label="Not Approved"
                                    key="Not Approved"></el-option>
                                <el-option class="select-danger" value="1" label="Approved" key="Approved"></el-option>
                            </el-select>
                        </base-input>
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="formCharValue.show = false">{{ tt('close')
                            }}</base-button>
                        <base-button type="primary" v-on:click="saveCharValue"
                            :disabled="btnSaveCharValue.onLoading || invalid">
                            <span v-if="btnSaveCharValue.onLoading"><i class="fas fa-spinner fa-spin"></i> {{
                                tt('please_wait') }}</span>
                            <span v-else>
                                <span v-if="formCharValue.add">{{ tt('add') }}</span>
                                <span v-else>{{ tt('edit') }}</span>
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>

            <!-- replace character value -->
            <validation-observer v-slot="{ invalid, reset }" ref="observer">
                <modal :show.sync="formReplaceCharValue.show" @hide="resetForm">
                    <template slot="header">
                        <h5 class="modal-title">{{ tt('replace_char_value', {char: formReplaceCharValue.char}) }}</h5>
                    </template>
                    <div>
                        <label class="form-control-label">{{ tt('find_char_val') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('find_char_val')" 
                            :placeholder="tt('fill_with_existing_char_value')" 
                            v-model="formReplaceCharValue.existingVal" 
                            rules="required"
                        ></base-input>

                        <label class="form-control-label">{{ tt('replace_char_val') }}</label>
                        <base-input :name="tt('replace_char_val')" 
                            :placeholder="tt('fill_with_desired_char_value')" 
                            v-model="formReplaceCharValue.desiredVal" 
                        ></base-input>
                    </div>
                    <template slot="footer">
                        <base-button type="primary" v-on:click="replaceExistVal" :disabled="formReplaceCharValue.btnReplace.onLoading || invalid">
                            <span v-if="formReplaceCharValue.btnReplace.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                {{ tt('replace') }}
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>

        </div>
        <noaccess v-else />
    </div>
</template>
<script>
import { mapState } from 'vuex'
import Api from '@/helpers/api';
import characteristicItemName from '@/services/dictionary/characteristicItemName.service';
import standardItemName from '@/services/dictionary/standardItemName.service';
import characteristicMaster from '@/services/dictionary/characteristicMaster.service';


export default {
    watch: {
        'itemTypeFilter': function () {
            this.get()
        },
        'formReplaceCharValue.show'(newVal) {
            if (!newVal) {
                this.resetForm();
            }
        }
    },
    data() {
        return {
            searchWorkbench: {
                cin_code: [],
                characteristic: [],
                value: {
                    char_value: [],
                    type: 'search-material-by-characteristic',
                    item_name: ''
                }
            },
            itemTypeFilter: 'GEN',
            filterValueType: 'All Values',
            itemName: '',
            tes: 'X',
            showTable: false,
            onLoad: false,
            onLoadCharValue: false,
            loadTimeout: null,
            btnSave: {
                onLoading: false
            },
            btnSaveCharValue: {
                onLoading: false
            },
            btnAdd: false,
            btnEdit: false,
            btnEditValue: false,
            btnAddCharValue: false,
            searchCharValue: false,
            form: {
                add: true,
                title: this.tt('add_new_mcr'),
                show: false,
            },
            formCharValue: {
                add: true,
                title: this.tt('add_new_mcr'),
                show: false,
            },
            table: {
                search: '',
                total: 0,
                data: [],
                page: [],
            },
            tableCharValue: {
                search: '',
                total: 0,
                data: [],
                page: [],
            },
            errorMessage: null,
            links: [],
            characteristicItemName: {},
            standardItemName: [],
            characteristicMaster: [],
            characteristicValue: {
                current_value: '',
                abbreviation: '',
                approved: '0',
            },
            currentRow: {
                index: null
            },
            inc: '',
            loading: false,
            filterValue: {
                search_char_value: '',
                empty: false,
                zeroCount: false,
            },
            selectCharacteristic: {},
            formReplaceCharValue: {
                show: false,
                char: '',
                existingVal: '',
                desiredVal: '',
                btnReplace: {
                    onLoading: false
                }
            },
            resetValidation: null
        }
    },
    computed: {
        ...mapState({
            authUserPermission: state => state.auth.userPermission
        }),
    },
    mounted() {
        this.firstLoad(),
        this.$nextTick(() => {
            this.resetValidation = this.$refs.observer.reset;
        });
    },
    methods: {
        handleCurrentChange(val, index) {
            this.currentRow = val;
            this.currentRow.index = index;
            this.formReplaceCharValue.char = val.characteristic
            this.btnAddCharValue = true;
            this.onLoadCharValue = true;
            this.getCharValue();
        },
        handleSelectionChange(val) {
            console.log(val);
        },
        getCharValue() {
            let context = this;
            let search = '';
            let index = [];
            context.onLoadCharValue = true;

            Api(context, characteristicItemName.get_characteristic_value((this.tableCharValue.page.current_page ? this.tableCharValue.page.current_page : 1), context.currentRow.cin_code, { search_char_value: context.filterValue.search_char_value, empty: context.filterValue.empty, zero_count: context.filterValue.zeroCount})).onSuccess(response => {
                this.tableCharValue.total = response.data.data.data.total;
                this.tableCharValue.data = response.data.data.data.data;
                this.tableCharValue.page = response.data.data.data;

                // console.log(response.data.data.data.data)   
            }).onError(function (error) {
                if (error.response.status == 404) {
                    if (error.response.data.message == 'characteristic Value Is Empty') {
                        context.errorMessage = null;
                        context.tableCharValue.data = []
                    } else if (error.response.data.message == 'Validation Error') {
                        context.errorMessage = error.response.data;
                    }
                }
            }).onFinish(function () {
                context.onLoadCharValue = false;
            }).call()
        },
        // handleFilterValue() {
        //     this.filterValue.empty = !this.filterValue.empty;
        //     clearTimeout(this.loadTimeout);
        //     this.loadTimeout = setTimeout(() => {
        //         this.getCharValue()
        //     }, 100);
        // },
        handleFilterValue(command) {
            const actions = {
                'all_values': {empty: 'false', zeroCount: 'false'},
                'empty_abbrev': {empty: 'true', zeroCount: 'false'},
                'count_zero': {empty:'false', zeroCount: 'true'}
            }

            const act = actions[command.action]
            if (act) {
                Object.assign(this.filterValue, act)
                this.filterValueType = command.action == 'all_values' ? 'All Values' : command.action == 'empty_abbrev' ? 'Empty Abbrev Filter' : command.action == 'count_zero' ? 'Zero Count Filter' : ''
                this.getCharValue()
            }
        },
        async updateValue() {
            // const results = arrayOne.filter(({ value: id1 }) => !arrayTwo.some(({ value: id2 }) => id2 === id1));
            this.confirmDialog(this.tt('confirm_update')).then((res) => {
                if (res.value) {
                    let oldValue = [];
                    let context = this;
                    let result = {
                        id: [],
                        cinv_code: [],
                        cin_code: [],
                        characteristic_value: [],
                        abbreviation: [],
                        approved: [],
                    }
                    let newData = [];
                    this.btnEditValue = true;
                    Api(context, characteristicItemName.get_characteristic_value((this.tableCharValue.page.current_page ? this.tableCharValue.page.current_page : 1), context.currentRow.cin_code, { search_char_value: context.filterValue.search_char_value, empty: context.filterValue.empty })).onSuccess(response => {
                        oldValue = response.data.data.data.data
                        newData = context.tableCharValue.data.filter(context.comparer(oldValue));
                        // let newReplace = context.tableCharValue.data.filter(context.comparerReplace(oldValue))
                        // for(var i = 0; i < newData.length; i++){
                        //     let removeIndex = newReplace.findIndex( x => x.cinv_code == newData[i].cinv_code)
                        //     // newReplace.splice(removeIndex,1)
                        // }
                        if (newData.length > 0) {
                            for (var i = 0; i < newData.length; i++) {
                                result.id.push(newData[i].id)
                                result.cinv_code.push(newData[i].cinv_code)
                                result.cin_code.push(newData[i].cin_code)
                                result.characteristic_value.push(newData[i].characteristic_value)
                                result.abbreviation.push(newData[i].abbreviation)
                                if (newData[i].approved == true) {
                                    result.approved.push(1)
                                } else {
                                    result.approved.push(0)
                                }
                            }
                        } else {
                            context.$notify({
                                message: context.tt('characteristic_value_is_exists'),
                                type: 'danger',
                                verticalAlign: 'bottom',
                                horizontalAlign: 'left'
                            });
                            context.getCharValue();
                        }
                    }).onError(function (error) {
                        oldValue = []
                    }).onFinish(() => {
                        if (newData.length > 0) {
                            Api(context, characteristicItemName.update_char_value(result)).onSuccess((response) => {
                                context.getCharValue();
                                context.$notify({
                                    message: response.data.message,
                                    type: 'success',
                                    verticalAlign: 'bottom',
                                    horizontalAlign: 'left'
                                });
                            }).onError((err) => {
                                context.errorMessage = err.response.data;
                                context.$notify({
                                    message: err.response.data.message,
                                    type: 'danger',
                                    verticalAlign: 'bottom',
                                    horizontalAlign: 'left'
                                });
                            }).onFinish(() => {
                                context.btnEditValue = false;
                            }).call();
                        } else {
                            context.btnEditValue = false;
                        }
                    }).call()
                }
            });
            // console.log('data_new:'+this.tableCharValue.data);



        },
        comparer(data) {
            return function (current) {
                return data.filter(function (d) {
                    return d.id == current.id && d.cinv_code == current.cinv_code && d.characteristic_value == current.characteristic_value && d.abbreviation == current.abbreviation && d.approved == current.approved
                }).length == 0
            }
        },
        comparerReplace(data) {
            return function (current) {
                return data.filter(function (d) {
                    return d.cinv_code == current.cinv_code && d.characteristic_value == current.characteristic_value
                }).length == 0
            }
        },
        firstLoad() {
            this.onLoad = true;
            this.inc = this.$route.query.search ? this.$route.query.search : '';
            this.table.search = this.$route.query.search ? this.$route.query.search + ' - ' + this.$route.query.itemname : ''

            if (this.$route.query.search) {
                this.btnAdd = true;
                this.btnEdit = true;
            }

            this.get()
        },
        get() {
            let context = this;

            // if (context.table.search == '') {
            //     context.table.data = [];
            //     context.table.total = 0;
            //     context.showTable = false;
            // } else {       
            Api(context, characteristicItemName.get(this.table.page.current_page, { search: this.inc, item_type_filter: this.itemTypeFilter })).onSuccess(function (response) {
                context.table.total = response.data.data.total;
                context.table.data = response.data.data.data;
                context.table.page = response.data.data;
                context.onLoad = false;
                context.showTable = true;
            }).onError(function (error) {
                if (error.response.status == 404) {
                    context.table.data = '';
                    context.table.data = [];
                    context.table.total = 0;
                    context.onLoad = true;
                    context.showTable = false;
                }
            }).onFinish(function () {
                context.tableCharValue.data = [];
            }).call()
            // }
        },
        changeItemType(type) {
            this.itemTypeFilter = type;
        },
        getStandardItemName() {
            let context = this;
            Api(context, standardItemName.get(null, { per_page: 'none' })).onSuccess(function (response) {
                response.data.data.data.data.forEach(function (item, index) {
                    context.standardItemName[index] = {
                        'inc': item['inc'],
                        'value': item['inc'] + ' - ' + item['item_name']
                    };
                });
            }).onError(function (error) {
                if (error.response.status == 404) {
                    context.standardItemName = [];
                }
            })
                .call()
        },
        filter(item) {
            // console.log(this.table.search)

            // this.inc = this.table.search
            let valueSplit = this.table.search.split(' - ');
            this.inc = valueSplit[0];
            this.itemName = valueSplit[1];

            clearTimeout(this.loadTimeout);
            this.loadTimeout = setTimeout(() => {
                if (this.table.search == '') {
                    this.onLoad = true;
                    this.showTable = false;
                } else {
                    this.onLoad = true;
                    this.showTable = true;
                }
                this.btnAdd = true;
                this.btnEdit = true;
                this.btnAddCharValue = false;
                this.get()
            }, 100);

            // this.$router.push({ path: '/dictionary/characteristic-item-name?search=' + this.inc})
            this.$router.push({ path: `/dictionary/characteristic-item-name?search=${this.inc}&itemname=${this.itemName}` })
        },
        filterCharValue() {
            clearTimeout(this.loadTimeout);
            this.loadTimeout = setTimeout(() => {
                this.getCharValue()
            }, 100);
        },
        handleTableAction(command) {
            switch (command.action) {
                case 'editCharValue':
                    this.editCharValue(command.data);
                    break;
                case 'removeCharValue':
                    this.removeCharValue(command.data);
                    break;
                default:
                    break;
            }
        },
        create() {
            this.errorMessage = null;
            this.form.add = true;
            this.form.title = this.tt('add_char_item_name');
            this.form.show = true;
        },
        replaceCharValue() {
            this.formReplaceCharValue.show = true
            this.formReplaceCharValue.existingVal = ''
            this.formReplaceCharValue.desiredVal = ''
        },
        replaceExistVal() {
            if (this.formReplaceCharValue.existingVal != '') {
                this.confirmDialog(this.tt('confirm_replace')).then((result) => {
                    if (result.value) {
                        let abbrev = this.tableCharValue.data.some(item => item.abbreviation && item.abbreviation.includes(this.formReplaceCharValue.existingVal))
                        if (abbrev) {
                            let count = 0
                            this.tableCharValue.data = this.tableCharValue.data.map(all => {
                                if (all.abbreviation && all.abbreviation.includes(this.formReplaceCharValue.existingVal)) {
                                    all.abbreviation = all.abbreviation.replace(new RegExp(this.formReplaceCharValue.existingVal, 'g'), this.formReplaceCharValue.desiredVal)
                                    count++
                                }
                                return all
                            })

                            this.$notify({
                                message: 'All done with ' + count +' replacement. Please click EDIT for saving the changes.',
                                type: 'success',
                                verticalAlign: 'bottom',
                                horizontalAlign: 'left'
                            })

                            this.formReplaceCharValue.show = false
                            this.formReplaceCharValue.existingVal = ""
                            this.formReplaceCharValue.desiredVal = ""   
                        } else {
                            this.$notify({
                                message: 'Sorry, your ' + this.formReplaceCharValue.existingVal +' input is not found',
                                type: 'danger',
                                verticalAlign: 'bottom',
                                horizontalAlign: 'left'
                            })
                        }
                    }
                })   
            } else {
                this.$notify({
                    message: 'You haven\'t written in From what field',
                    type: 'danger',
                    verticalAlign: 'bottom',
                    horizontalAlign: 'left'
                })
            }
        },
        resetForm() {
            if (this.resetValidation) {
                this.resetValidation();
            }
        },
        createCharValue() {
            this.characteristicValue = {};
            this.formCharValue.add = true;
            this.formCharValue.title = this.tt('add_char_value');
            this.formCharValue.show = true;
            this.characteristicValue = {}
        },
        editCharValue(index) {
            let context = this;
            context.characteristicValue = {
                id: context.tableCharValue.data[index].id,
                current_value: context.tableCharValue.data[index].characteristic_value,
                current_char_value: context.tableCharValue.data[index].characteristic_value,
                characteristic_value: context.tableCharValue.data[index].characteristic_value,
                abbreviation: context.tableCharValue.data[index].abbreviation,
                approved: String(context.tableCharValue.data[index].approved),
            };
            context.formCharValue.add = false;
            context.formCharValue.title = context.tt('edit_characteristic_value');
            context.formCharValue.show = true;
        },
        update() {
            let context = this;
            let dataUpdate = {
                cin_code: [],
                sequence_char_item_name: [],
                sequence_shortdes: [],
                separator: [],
                mandatory: [],
            };
            context.btnEdit = false;

            for (var i = 0; i < context.table.data.length; i++) {
                dataUpdate.mandatory[i] = context.table.data[i].mandatory;
                dataUpdate.cin_code[i] = context.table.data[i].cin_code;
                dataUpdate.sequence_char_item_name[i] = context.table.data[i].sequence;
                dataUpdate.sequence_shortdes[i] = context.table.data[i].sequence_shortdes;
                dataUpdate.separator[i] = (context.table.data[i].separator_code == null) ? '' : context.table.data[i].separator_code;
            }
            Api(context, characteristicItemName.update_char_item_name(context.inc, dataUpdate)).onSuccess(function (response) {
                context.get();
                context.$notify({
                    message: response.data.message,
                    type: 'success',
                    verticalAlign: 'bottom',
                    horizontalAlign: 'left'
                });
            }).onFinish(function () {
                context.btnEdit = true;
            }).call()
        },
        save() {
            let context = this;
            let api = null;
            context.onLoad = true;
            context.btnSave.onLoading = true;

            context.characteristicItemName.inc = context.inc;

            if (context.form.add) {
                api = Api(context, characteristicItemName.add_char_item_name(context.characteristicItemName));
            } else {
                {
                    api = Api(context, characteristicItemName.update(context.characteristicItemName.id, context.characteristicItemName));
                }
            }

            api.onSuccess(function (response) {
                context.get();
                context.$notify({
                    message: response.data.message,
                    type: 'success',
                    verticalAlign: 'bottom',
                    horizontalAlign: 'left'
                });
                context.characteristicItemName = {};
                context.form.show = false;
            }).onError(function (error) {
                if (error.response.status == 404) {
                    if (error.response.data.message == 'Characteristic is Exists') {
                        context.$notify({
                            message: error.response.data.message,
                            type: 'danger',
                            verticalAlign: 'bottom',
                            horizontalAlign: 'left'
                        });
                        context.errorMessage = null;
                    } else if (error.response.data.message == 'Validation Error') {
                        context.errorMessage = error.response.data;
                    }
                } else if (error.response.status == 500) {
                    context.errorMessage = {
                        status: "Fail",
                        message: "Validation Error",
                        data: {
                            characteristic_code: [
                                "The inc field is required."
                            ]
                        }
                    };
                }
            }).onFinish(function () {
                context.onLoad = false;
                context.btnSave.onLoading = false;
            })
                .call();
        },
        saveCharValue() {
            let context = this;
            let api = null;
            context.btnSaveCharValue.onLoading = true;

            context.characteristicValue.cin_code = context.currentRow.cin_code;
            if (context.formCharValue.add) {
                api = Api(context, characteristicItemName.add_char_value(context.characteristicValue));
            } else {
                {
                    api = Api(context, characteristicItemName.update_char_value(context.characteristicValue.id, context.characteristicValue));
                }
            }
            api.onSuccess(function (response) {
                context.getCharValue();
                context.$notify({
                    message: response.data.message,
                    type: 'success',
                    verticalAlign: 'bottom',
                    horizontalAlign: 'left'
                });
                context.formCharValue.show = false;
            }).onError(function (error) {
                context.errorMessage = error.response.data;
                context.$notify({
                    message: context.tt('characteristic_value_is_exists'),
                    type: 'danger',
                    verticalAlign: 'bottom',
                    horizontalAlign: 'left'
                });
            }).onFinish(function () {
                context.characteristicValue = {
                    current_value: '',
                    approved: '0',
                },
                    context.btnSaveCharValue.onLoading = false;
            })
                .call();
        },
        remove(id) {
            let context = this;
            context.confirmDialog(this.tt('confirm_delete')).then((result) => {
                if (result.value) {
                    context.onLoad = true;
                    Api(context, characteristicItemName.delete_char_item_name(id)).onSuccess(function (response) {
                        context.$notify({
                            message: response.data.message,
                            type: 'success',
                            verticalAlign: 'bottom',
                            horizontalAlign: 'left'
                        });
                        context.onLoad = false;
                    }).onFinish(function () {
                        context.onLoad = false;
                        context.get();
                    }).call();
                }
            })
        },
        removeCharValue(id) {
            let context = this;
            context.confirmDialog(this.tt('confirm_delete')).then((result) => {
                if (result.value) {
                    context.onLoadCharValue = true;
                    Api(context, characteristicItemName.delete_char_value(id)).onSuccess(function (response) {
                        context.$notify({
                            message: response.data.message,
                            type: 'success',
                            verticalAlign: 'bottom',
                            horizontalAlign: 'left'
                        });
                    }).onFinish(function () {
                        context.onLoadCharValue = false;
                        context.getCharValue();
                    }).call();
                }
            })
        },
        querySearchStandardItemName(query) {
            if (query.length > 2) {
                clearTimeout(this.loadTimeout);
                let context = this;

                this.loading = true;

                this.loadTimeout = setTimeout(() => {
                    Api(context, standardItemName.get(null, { per_page: 'none', item_name: query })).onSuccess(function (response) {
                        context.standardItemName = response.data.data.data.data;
                        context.loading = false;
                    }).onError(function (error) {
                        context.standardItemName = [];
                        context.loading = false;
                    }).call();
                }, 200)
            }
        },
        handleCopyAll() {
            let context = this;
            // Tampilkan dialog konfirmasi
            context.confirmDialog('Anda yakin ingin menyalin semua singkatan?').then(() => {
                // Mendapatkan semua nilai characteristic_value
                const allCharacteristicVal = context.tableCharValue.data.map(row => row.characteristic_value);

                // Mengupdate setiap elemen abbreviation hanya jika kosong
                context.tableCharValue.data.forEach((row, index) => {
                    if (!row.abbreviation) {
                        // Menggunakan $set untuk memastikan Vue reaktif terhadap perubahan
                        context.$set(context.tableCharValue.data, index, {
                            ...row,
                            abbreviation: allCharacteristicVal[index]
                        });
                    }
                });

                // Menyalin semua nilai characteristic_value ke clipboard sebagai string
                navigator.clipboard.writeText(allCharacteristicVal.join(', ')).then(() => {
                    // Memastikan this.$message dan this.$message.success ada sebelum mengaksesnya
                    if (context.$message && context.$message.success) {
                        context.$message.success('Semua singkatan berhasil disalin!');
                    } else {
                        // Sebagai fallback, menggunakan console.log jika this.$message.success tidak ada
                        console.log('Semua singkatan berhasil disalin!');
                    }
                }).catch(err => {
                    console.error('Gagal menyalin teks: ', err);
                    // Memastikan this.$message dan this.$message.error ada sebelum mengaksesnya
                    if (context.$message && context.$message.error) {
                        context.$message.error('Gagal menyalin singkatan.');
                    }
                });
            }).catch(() => {
                // Jika pengguna membatalkan operasi
                console.log('Pengguna membatalkan operasi.');
                // Optional: Tambahkan pesan atau logika tambahan untuk memperlakukan pembatalan operasi
                if (context.$message && context.$message.info) {
                    context.$message.info('Operasi telah dibatalkan.');
                }
            });
        },

        querySearchCharacteristicMaster(query) {
            if (query.length > 2) {
                clearTimeout(this.loadTimeout);
                let context = this;

                this.loading = true;

                this.loadTimeout = setTimeout(() => {
                    Api(context, characteristicMaster.getCharacteristicByType(null, { per_page: 'none', search: query, type_code: context.itemTypeFilter })).onSuccess(function (response) {
                        context.selectCharacteristic = response.data.data.data.data;
                        context.loading = false;
                    }).onError(function (error) {
                        context.selectCharacteristic = [];
                        context.loading = false;
                    }).call();
                }, 200)
            }
        },

        createFilter(queryString) {
            return (link) => {
                return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) > -1);
            };
        },
        loadAll(materialType) {
            return materialType;
        },
        changePage(page) {
            let context = this;
            context.onLoad = true;

            clearTimeout(this.loadTimeout);
            this.loadTimeout = setTimeout(() => {
                this.get()
            }, 100);
        },
        changePageCharValue(page) {
            let context = this;
            context.onLoadCharValue = true;

            clearTimeout(this.loadTimeout);
            this.loadTimeout = setTimeout(() => {
                this.getCharValue()
            }, 100);
        },
        workbench(data) {
            this.searchWorkbench.value.char_value = []
            this.searchWorkbench.value.item_name = this.$route.query.search;
            this.searchWorkbench.value.char_value.push(data.characteristic_value)
            this.searchWorkbench.value.char_value.push('')
            this.searchWorkbench.value.cinv_code = data.cinv_code
            let routeLink = this.$router.resolve({ name: 'WorkBench', query: this.searchWorkbench.value })
            window.open(routeLink.href, '_blank')
            // this.$router.push({ name: 'WorkBench', query: this.searchWorkbench.value });
        },
        handleCopy(row) {
            console.log(row)
            let searchIndex = this.tableCharValue.data.map(element => element.id).indexOf(row.id)
            this.tableCharValue.data[searchIndex].abbreviation = row.characteristic_value
        }

    }
};
</script>
<style>
.selected-row {
    background-color: #ecf5ff;
    color: white;
}

div.el-table tbody td,
div.el-table thead th {
    padding: 0rem;
}

.form-control-sm {
    height: 25px
}

.filter.mb--1:hover {
    color: #e9ecef;
    cursor: pointer;
}

.el-table__body tr.current-row>td {
    background-color: #ffe391 !important;
}

/*#cardCharacteristic.card .table td, #cardCharacteristic.card .table th, #cardCharacteristic.card .el-table td, #cardCharacteristic.card .el-table th{
        padding-left:0.1rem;
        padding-right:0.1rem;
    }*/
#tableChar.table thead th,
#tableChar.el-table thead th {
    letter-spacing: 0px;
    font-size: 0.59rem;
}

#tableChar.table tbody td,
#tableChar.el-table tbody td {
    letter-spacing: 0px;
    /*font-size: 0.69rem;*/
}

.el-autocomplete-suggestion li {
    overflow: visible;
}
</style>